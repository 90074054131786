/*
  DO NOT USE | IN YOUR HEADER NAME(s)
 */
export function generateAsciiTable<T>(
  items: T[] | undefined,
  headers: Map<string, keyof T>,
  debug = false
): string {
  if (items?.length === 0 || !items) return "";

  const columnWidths: { [key: string]: number } = {};

  headers.forEach((_value, key) => {
    columnWidths[key] = key.length;
  });

  items.forEach((item) => {
    headers.forEach((value, key) => {
      columnWidths[key] = Math.max(
        columnWidths[key],
        item[value]?.toString()?.length as number
      );
    });
  });

  const rowFormat = (row: string[]) => {
    return (
      "| " +
      row
        .map((cell, index) =>
          cell.trim().padEnd(columnWidths[Array.from(headers.keys())[index]])
        )
        .join(" | ") +
      " |"
    );
  };

  const tableLines: string[] = [];

  const border =
    "+-" +
    Array.from(headers.keys())
      .map((header) => "-".repeat(columnWidths[header]))
      .join("-+-") +
    "-+";
  tableLines.push(border);

  tableLines.push(rowFormat(Array.from(headers.keys())));

  const separator =
    "+-" +
    Array.from(headers.keys())
      .map((header) => "-".repeat(columnWidths[header]))
      .join("-+-") +
    "-+";
  tableLines.push(separator);

  items.forEach((item) => {
    const rowValues = Array.from(headers.values()).map((key) => {
      const value = item[key];
      return value != null ? value.toString() : "";
    });

    tableLines.push(rowFormat(rowValues));
  });

  tableLines.push(border);

  const result = tableLines.join("\n");
  if (debug) {
    console.dir(result);
  }
  return result;
}
