import {
  GET_BACKUP_STATS,
  GET_BACKUP_STATS_ERROR,
  GET_BACKUP_STATS_SUCCESS
} from "../modules/backup/actions/backupActions";

import fetch from "isomorphic-fetch";
import AuthFetch from "../auth/AuthFetch";

const apiEndpoint = `${import.meta.env.API_ENDPOINT}/api/backup`;
let has403Error = false;

const FORBIDDEN_ERROR = " 403 Forbidden";

const backupApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case GET_BACKUP_STATS: {
      if (has403Error) {
        return;
      }

      AuthFetch(fetch, `${apiEndpoint}/order/backupstats`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => {
          if (response.status === 403) {
            has403Error = true;
            throw new Error(FORBIDDEN_ERROR);
          }
          return response.json();
        })
        .then((data) => {
          return next({
            type: GET_BACKUP_STATS_SUCCESS,
            payload: {
              data: data
            }
          });
        })
        .catch((err) => {
          if (err.message === FORBIDDEN_ERROR) {
            return next({
              type: GET_BACKUP_STATS_ERROR,
              payload: {
                data: FORBIDDEN_ERROR
              }
            });
          } else if (!Object.keys(err).length) {
            return next({
              type: GET_BACKUP_STATS_ERROR,
              payload: {
                data: "Error"
              }
            });
          } else {
            return next({
              type: GET_BACKUP_STATS_ERROR,
              payload: {
                data: ""
              }
            });
          }
        });
      break;
    }
    default:
      return store;
  }
};

export default backupApi;