import { useQuery } from "@tanstack/react-query";
import {
  AssessmentProgram,
  AssessmentRecommendationsDto,
  AssessmentsClient,
  PrioritizedRecommendation,
  ResponseRecommendation,
} from "@lib/ShiOneClient";
import { CACHE_KEY_ASSESSMENTS_RECOMMENDATIONS } from "@features/assessments-feature/constants";
import ms from "ms";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";

const api = new AssessmentsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT,
);

const useGetAssessmentRecommendations = (
  assessmentProgram?: AssessmentProgram,
  assessmentId?: string,
) => {
  const { setDemoMode, isDemoMode } = useAssessmentDemoData();
  const queryFn = async () => {
    if (!assessmentProgram) return null;
    if (isDemoMode.get(assessmentProgram)) return null;
    const result = api.getAssessmentRecommendations(
      assessmentProgram,
      assessmentId,
    );
    return result
      .then((r) => {
        return r;
      })
      .catch((except) => {
        if (except.status === 404) {
          setDemoMode(true, assessmentProgram);
          return null;
        }
      });
  };

  return useQuery<AssessmentRecommendationsDto, Error>({
    queryKey: [
      CACHE_KEY_ASSESSMENTS_RECOMMENDATIONS,
      assessmentProgram,
      assessmentId,
    ],
    queryFn: async () => await queryFn().then(),
    staleTime: ms("1m"),
    enabled: !!assessmentProgram && !isDemoMode.get(assessmentProgram),
    placeholderData: new AssessmentRecommendationsDto({
      prioritizedRecommendations: [
        new PrioritizedRecommendation({
          priority: "Loading",
          responseRecommendations: [
            new ResponseRecommendation({
              impact: "loading",
              capability: "Placeholder data",
              recommendations: "Placeholder recommendations",
              knownTools: ["loading"],
              knownToolsLogos: ["loading"],
            }),
          ],
        }),
      ],
    }),
  });
};
export default useGetAssessmentRecommendations;
