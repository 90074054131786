import {
  MicrosoftAdoptionWidgetsClient,
  PageLayoutWidgetDto,
  LicenseUtilizationWidgetResponse,
} from "@lib/ShiOneClient";
import { WidgetWrapper } from "shared-ui";
import React, { useEffect, useState } from "react";
import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { NoContent } from "../../dashboards/framework";
import { RankingWidgetContainerHeight } from "@widgets/standard";
import shiColors from "shared-ui/src/theme/shiColors";

Chart.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

function useGetWidgetData() {
  const api = useApi(MicrosoftAdoptionWidgetsClient);

  return useQuery<LicenseUtilizationWidgetResponse, Error>({
    queryKey: ["license-utilization-widget"],
    queryFn: () => api.getLicenseUtilizationWidget(),
    staleTime: ms("10m"),
    enabled: true,
  });
}

const chartDataInitialState = { labels: [], datasets: [] };

export default function LicenseUtilizationWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>(chartDataInitialState);
  const { data, isError } = useGetWidgetData();

  useEffect(() => {
    if (data?.licenses && data.licenses.length > 0) {
      let labels = data.licenses.map((license) => license.licenseName ?? "");
      let active = data.licenses.map(
        (license) => ((license.active ?? 0) / (license.total ?? 1)) * 100,
      );
      let onboarding = data.licenses.map(
        (license) => ((license.onboarding ?? 0) / (license.total ?? 1)) * 100,
      );
      let inactive = data.licenses.map(
        (license) => ((license.inactive ?? 0) / (license.total ?? 1)) * 100,
      );
      let notUsed = data.licenses.map(
        (license) => ((license.notUsed ?? 0) / (license.total ?? 1)) * 100,
      );

      let datasets = [
        {
          label: "Active",
          data: active,
          stack: "stack",
          backgroundColor: shiColors.green.light,
        },
        // Should be reintroduced once we have data
        // {
        //   label: "Onboarding",
        //   data: onboarding,
        //   stack: "stack",
        //   backgroundColor: shiColors.orange.lighter,
        // },
        {
          label: "Inactive",
          data: inactive,
          stack: "stack",
          backgroundColor: shiColors.gray.lighter,
        },
        // Should be reintroduced once we have data
        // {
        //   label: "Not Used",
        //   data: notUsed,
        //   stack: "stack",
        //   backgroundColor: shiColors.red.DEFAULT,
        // },
      ];
      setChartData({ labels, datasets });
    } else {
      setChartData(chartDataInitialState);
    }
  }, [data]);

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          maxTicksLimit: 7,
          callback: function (value: any) {
            return value + "%";
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.label || "";
            const itemLabel = context.dataset.label || "";

            // @ts-ignore chart only displays if there is data so this is safe
            const dataItem = data.licenses.filter(
              (l) => l.licenseName === label,
            )[0];

            const camelItemLabel = (
              itemLabel.charAt(0).toLowerCase() + itemLabel.slice(1)
            ).replace(" ", "");

            // @ts-ignore chart item labels mirror types with capital and spaces so this is safe e.g. label: Not Used matches type notUsed
            const count = dataItem[camelItemLabel];

            return `${itemLabel}: ${count}`;
          },
          footer: function (context: any) {
            return (
              "Total: " +
              data?.licenses?.filter(
                (d) => d.licenseName === context[0].label,
              )[0].total
            );
          },
        },
      },
    },
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
  };

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={isError}
      noPadding={true}
      isEmpty={chartData.datasets.length <= 0}
    >
      {chartData.datasets.length <= 0 ? (
        <NoDataElement />
      ) : (
        <Bar data={chartData} options={chartOptions} />
      )}
    </WidgetWrapper>
  );
}

function NoDataElement() {
  return (
    <NoContent
      containerHeight={RankingWidgetContainerHeight}
      header={"No License Data"}
      body={"No data found for this widget."}
      actionText={""}
      actionLink={""}
      icon={""}
    />
  );
}
