import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  IntegrationType,
  Microsoft365CallbackDetails,
  RegistrationError,
} from "@lib/ShiOneClient";
import axios from "axios";

const Microsoft365ReportingRegistrationCallback: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const sessionState = searchParams.get("session_state");

  const extractFromState = (state: string | null) => {
    if (!state) {
      return { registrationId: null, registrationType: null };
    }

    try {
      const pairs = state.split("&").map((pair) => pair.split("="));
      const stateParams = pairs.reduce(
        (acc, [key, value]) => {
          acc[decodeURIComponent(key)] = decodeURIComponent(value);
          return acc;
        },
        {} as Record<string, string>,
      );

      return {
        registrationId: stateParams["registration_id"] || null,
        registrationType: stateParams["registration_type"] || null,
      };
    } catch {
      return { registrationId: null, registrationType: null };
    }
  };

  useEffect(() => {
    if (error !== null) {
      const logError = async () => {
        const paramsObject: Record<string, string> = {};

        for (let [key, value] of searchParams.entries()) {
          paramsObject[key] = value;
        }

        const logMessage = `Error Message: ${error}
        Query Parameters: ${JSON.stringify(paramsObject, null, 2)}
        Browser Details:
            User-Agent: ${navigator.userAgent}
        Language: ${navigator.language}
        URL: ${window.location.href}`;

        const registrationError = new RegistrationError({
          errorInformation: logMessage,
        });

        try {
          await axios.post(
            `${
              import.meta.env.API_ENDPOINT
            }/api/Integration/Microsoft365/registration-error`,
            registrationError,
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          );
        } catch (apiError) {
          console.error("Failed to log the error:", apiError);
        }
      };

      logError();
    }
  }, [error, searchParams]);

  useEffect(() => {
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const validateAndSubmit = async () => {
      try {
        setProgress(10);
        await delay(1000);

        // Validate query parameters
        if (!code || !state || !sessionState) {
          setError("Invalid query parameters. Please verify the link.");
          return;
        }

        if (!isValidGuid(sessionState)) {
          setError("Invalid session state format.");
          return;
        }

        // Simulated delay for extracting parameters
        setProgress(33);
        await delay(1000);

        const { registrationId, registrationType } = extractFromState(state);

        if (!registrationId || !isValidGuid(registrationId)) {
          setError("Invalid or missing 'registration_id'.");
          return;
        }

        if (
          !registrationType ||
          !Object.values(IntegrationType).includes(
            registrationType as IntegrationType,
          )
        ) {
          setError("Invalid or missing 'registration_type'.");
          return;
        }

        setProgress(66);

        const details = new Microsoft365CallbackDetails({
          registrationId: registrationId,
          code: code,
          registrationType: IntegrationType.Microsoft365Reports,
          sessionState: sessionState,
        });

        const result = await axios.post<boolean>(
          `${
            import.meta.env.API_ENDPOINT
          }/api/Integration/Microsoft365/registration-callback`,
          details,
        );
        setProgress(100);

        setSuccess(result.data);
      } catch {
        setError(
          "Failed to validate credentials. Please contact your SHI expert.",
        );
      }
    };

    validateAndSubmit();
  }, [code, state, sessionState]);

  const isValidGuid = (value: string) => {
    const guidPattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guidPattern.test(value);
  };

  return (
    <Box sx={{ padding: 4, maxWidth: 600, margin: "auto" }}>
      <Paper sx={{ padding: 3 }}>
        <Typography variant="h5" gutterBottom>
          Connection Registration
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ marginBottom: 2 }}
        />
        {(() => {
          if (error) {
            return (
              <Alert severity="error">
                {error} This issue has been logged for further investigation.
              </Alert>
            );
          }
          if (success) {
            return (
              <Alert severity="success">
                Your app registration is complete!
              </Alert>
            );
          }
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography>Processing your registration...</Typography>
              <CircularProgress sx={{ marginTop: 2 }} />
            </Box>
          );
        })()}
      </Paper>
    </Box>
  );
};

export default Microsoft365ReportingRegistrationCallback;
