import { useQuery, UseQueryResult } from "@tanstack/react-query";
import ms from "ms";
import { Box, Stack, Theme, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { BaseWidgetProps, WidgetWrapper, SkeletonWrapper } from "../framework";
import ErrorIcon from "@mui/icons-material/Error";

export type KeyInformationWidgetProps = BaseWidgetProps & {
  useQueryFunction: () =>
    | UseQueryResult<ItemBoxConfiguration[], unknown>
    | UseQueryResult<ItemBoxConfiguration, unknown>[];
  cardActions?: ReactNode;
  headerAction?: ReactNode;
  noContentElement?: ReactNode;
};

export type ItemBoxConfiguration = {
  amount: ReactNode | number | string | undefined;
  label: string | ReactNode;
  icon: ReactNode;
  tileActions?: ReactNode;
};

const css = (theme: Theme) => ({
  assetBox: {
    display: "flex",
    padding: theme.spacing(2),
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
    background: theme.palette.background.default,
  },
  iconFormat: {
    color: theme.palette.text.secondary,
    "& svg": {
      height: "32px",
      width: "32px",
    },
  },
});

export function KeyInformationLabel({ label }: { label: string }) {
  const theme = useTheme();

  return (
    <Typography variant={"body2"} color={theme.palette.text.secondary}>
      {label}
    </Typography>
  );
}

export function ItemBox({
  data,
  label,
  icon,
  loading,
  error,
  tileActions,
}: {
  data?: ReactNode | number | string;
  label?: string | ReactNode;
  icon?: ReactNode;
  loading?: boolean;
  error?: boolean;
  tileActions?: ReactNode;
}) {
  const theme = useTheme();
  const styles = css(theme);
  const numberFormatter = new Intl.NumberFormat("en-US");
  const formattedData = (data: number | string | ReactNode) =>
    typeof data === "number" ? numberFormatter.format(data) : data;
  return (
    <Box sx={styles.assetBox}>
      <SkeletonWrapper loading={loading} error={error}>
        <Box sx={styles.iconFormat}>{icon}</Box>
      </SkeletonWrapper>
      <Box>
        <SkeletonWrapper loading={loading} error={error}>
          <Typography variant={"h6"}>{formattedData(data ?? 0)}</Typography>
        </SkeletonWrapper>
        {error && <ErrorIcon color="error" />}
      </Box>
      <Box>
        <SkeletonWrapper loading={loading} error={error}>
          {typeof label === "string" ? (
            <KeyInformationLabel label={label} />
          ) : (
            label
          )}
        </SkeletonWrapper>
      </Box>
      {!loading && tileActions && <Box>{tileActions}</Box>}
    </Box>
  );
}
export function KeyInformationWidgetContainer({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Stack
      direction="row"
      gap={2}
      sx={{ display: "flex", alignItems: "stretch", flexWrap: "wrap" }}
    >
      {children}
    </Stack>
  );
}

export function useKeyInformationData<ApiDataType>({
  queryKey,
  apiFunction,
  transformFunction,
  placeholderData,
}: {
  queryKey: string[];
  apiFunction: (param?: any) => Promise<ApiDataType>;
  transformFunction: (
    rawData: ApiDataType | undefined,
  ) => ItemBoxConfiguration[];
  placeholderData: ApiDataType;
}): UseQueryResult<ItemBoxConfiguration[], unknown> {
  return useQuery(
    queryKey,
    (param) => apiFunction(param).then((r) => transformFunction(r)),
    {
      staleTime: ms("10m"),
      placeholderData: transformFunction(placeholderData),
    },
  );
}

export function KeyInformationWidget({
  pageLayoutWidget,
  useQueryFunction,
  cardActions,
  headerAction,
  noContentElement,
}: KeyInformationWidgetProps) {
  const response = useQueryFunction();

  if (response instanceof Array) {
    return (
      <WidgetWrapper pageLayoutWidget={pageLayoutWidget}>
        <KeyInformationWidgetContainer>
          {response.map((item, index) => {
            const key =
              typeof item.data?.label === "string"
                ? item.data.label
                : `label-${index}`;
            return (
              <ItemBox
                key={key}
                {...item.data}
                loading={item.isLoading || item.isPlaceholderData}
                error={item.isError}
              />
            );
          })}
        </KeyInformationWidgetContainer>
      </WidgetWrapper>
    );
  }
  return (
    <WidgetWrapper
      headerAction={headerAction}
      pageLayoutWidget={pageLayoutWidget}
      cardActions={cardActions}
      error={response?.isError}
      isEmpty={response.data?.length === 0 && !!noContentElement}
    >
      {response.data?.length === 0 && noContentElement ? (
        noContentElement
      ) : (
        <KeyInformationWidgetContainer>
          {response.data?.map((item, index) => (
            <ItemBox
              key={
                typeof item.label === "string" ? item.label : `label-${index}`
              }
              data={item.amount}
              label={item.label}
              icon={item.icon}
              loading={response.isPlaceholderData}
              error={response.isError}
              tileActions={item.tileActions}
            />
          ))}
        </KeyInformationWidgetContainer>
      )}
    </WidgetWrapper>
  );
}
