import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store";
import { AccountAdminState } from "./accountAdminTypes";
import {
  createUser,
  updateUser,
  activateUser,
  getMappedUserProfile,
  postRestRejectUser,
  postRestPagedUserProfiles,
  getRestAccountNotifications,
  getRestAccounts,
  getRestAccountReports,
} from "@api/accountAdminStore";
import {
  AccountNotifications,
  AccountResponse,
  AdminDashboardReports,
  ApprovalResponse,
  Contact,
  ProfileApprovals,
  UpdateUserObject,
  UserProfile,
  UserProfileManager,
  UserProfilesRequest,
} from "lib/ShiOneClient";
import GlobalConstants from "@constants";

import _ from "lodash";

const initialState: AccountAdminState = {
  accountsFetchStatus: GlobalConstants.fetchStatus.idle,
  accounts: [],
  accountNotificationsFetchStatus: GlobalConstants.fetchStatus.idle,
  accountNotifications: {} as AccountNotifications,
  accountReportsFetchStatus: GlobalConstants.fetchStatus.idle,
  accountReports: {} as AdminDashboardReports,
  rejectedWaitingUsers: [],
  rejectWaitingUserStatus: GlobalConstants.fetchStatus.idle,
  mappedUserProfilesFetchStatus: GlobalConstants.fetchStatus.idle,
  mappedUserProfiles: [],
  createdUser: {} as ApprovalResponse,
  createUserStatus: GlobalConstants.fetchStatus.idle,
  updateUserStatus: GlobalConstants.fetchStatus.idle,
  activateUserStatus: GlobalConstants.fetchStatus.idle,
  pagedUsers: {} as UserProfileManager,
  pagedUsersFetchStatus: GlobalConstants.fetchStatus.idle,
};

const accountAdminSlice = createSlice({
  name: "accountAdmin",
  initialState,
  reducers: {
    fetchingAccounts(state) {
      state.accountsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    fetchedAccounts(state, action: PayloadAction<AccountResponse>) {
      state.accounts = action.payload.accounts ?? [];
      state.accountsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    fetchAccountsError(state) {
      state.accountsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    fetchingAccountNotifications(state) {
      state.accountNotificationsFetchStatus =
        GlobalConstants.fetchStatus.loading;
    },
    fetchedAccountNotifications(
      state,
      action: PayloadAction<AccountNotifications>,
    ) {
      state.accountNotifications = action.payload;
      state.accountNotificationsFetchStatus =
        GlobalConstants.fetchStatus.complete;
    },
    fetchAccountNotificationsError(state) {
      state.accountNotificationsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    fetchingAccountReports(state) {
      state.accountReportsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    fetchedAccountReports(state, action: PayloadAction<AdminDashboardReports>) {
      state.accountReports = action.payload;
      state.accountReportsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    fetchAccountReportsError(state) {
      state.accountReportsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    submittingUserRejection(state) {
      state.rejectWaitingUserStatus = GlobalConstants.fetchStatus.loading;
    },
    submittedUserRejection(state, action: PayloadAction<number>) {
      state.rejectedWaitingUsers = [
        ...state.rejectedWaitingUsers,
        action.payload,
      ];
      state.rejectWaitingUserStatus = GlobalConstants.fetchStatus.complete;
    },
    submitUserRejectionError(state) {
      state.rejectWaitingUserStatus = GlobalConstants.fetchStatus.error;
    },
    receivingMappedUserProfiles(state) {
      state.mappedUserProfilesFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveMappedUserProfiles(state, action: PayloadAction<UserProfile[]>) {
      state.mappedUserProfilesFetchStatus =
        GlobalConstants.fetchStatus.complete;
      state.mappedUserProfiles = action.payload;
    },
    receiveMappedUserProfilesError(state) {
      state.mappedUserProfilesFetchStatus = GlobalConstants.fetchStatus.error;
    },
    creatingUser(state) {
      state.createUserStatus = GlobalConstants.fetchStatus.loading;
    },
    createdUser(state, action: PayloadAction<ApprovalResponse>) {
      state.createUserStatus = GlobalConstants.fetchStatus.complete;
      const userProfilesWithContact = combinedUserProfilesWithContact(
        action.payload,
      );
      state.mappedUserProfiles.push.apply(
        state.mappedUserProfiles,
        userProfilesWithContact,
      );
      state.createdUser = action.payload;
    },
    createUserError(state) {
      state.createUserStatus = GlobalConstants.fetchStatus.error;
    },
    createUserStatusReset(state) {
      state.createUserStatus = initialState.createUserStatus;
      state.createdUser = initialState.createdUser;
    },
    updatingUser(state) {
      state.updateUserStatus = GlobalConstants.fetchStatus.loading;
    },
    updatedUser(state, action: PayloadAction<ApprovalResponse>) {
      state.updateUserStatus = GlobalConstants.fetchStatus.complete;
      state.mappedUserProfiles = updateMappedUserProfile(
        state.mappedUserProfiles,
        action.payload,
      );
    },
    updateUserError(state) {
      state.updateUserStatus = GlobalConstants.fetchStatus.error;
    },
    resetUpdateUser(state) {
      state.updateUserStatus = GlobalConstants.fetchStatus.idle;
    },
    activatingUser(state) {
      state.updateUserStatus = GlobalConstants.fetchStatus.loading;
    },
    activatedUser(state, action: PayloadAction<ApprovalResponse>) {
      state.updateUserStatus = GlobalConstants.fetchStatus.complete;
    },
    activateUserError(state) {
      state.updateUserStatus = GlobalConstants.fetchStatus.error;
    },
    fetchingNewPagedUsers(state) {
      state.pagedUsersFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    successFetchingNewPagedUsers(state, action: PayloadAction<any>) {
      state.pagedUsersFetchStatus = GlobalConstants.fetchStatus.complete;
      state.pagedUsers = action.payload;
    },
    errorFetchingNewPagedUsers(state) {
      state.pagedUsersFetchStatus = GlobalConstants.fetchStatus.error;
    },
  },
});

export const getAccounts = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(accountAdminSlice.actions.fetchingAccounts());
  try {
    const accountResponse = await getRestAccounts();
    dispatch(accountAdminSlice.actions.fetchedAccounts(accountResponse));
  } catch {
    dispatch(accountAdminSlice.actions.fetchAccountsError());
  }
};

export const getAccountNotifications =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(accountAdminSlice.actions.fetchingAccountNotifications());
    try {
      const accountNotifications = await getRestAccountNotifications();
      dispatch(
        accountAdminSlice.actions.fetchedAccountNotifications(
          accountNotifications,
        ),
      );
    } catch {
      dispatch(accountAdminSlice.actions.fetchAccountNotificationsError());
    }
  };

export const getAccountReports =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(accountAdminSlice.actions.fetchingAccountReports());
    try {
      const accountReports = await getRestAccountReports();
      dispatch(accountAdminSlice.actions.fetchedAccountReports(accountReports));
    } catch {
      dispatch(accountAdminSlice.actions.fetchAccountReportsError());
    }
  };

export const rejectWaitingUser =
  (id: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(accountAdminSlice.actions.submittingUserRejection());
    try {
      await postRestRejectUser(id);
      dispatch(accountAdminSlice.actions.submittedUserRejection(id));
    } catch {
      dispatch(accountAdminSlice.actions.submitUserRejectionError());
    }
  };

export const getMappedUserProfiles =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(accountAdminSlice.actions.receivingMappedUserProfiles());
    try {
      const mappedUserProfiles = await getMappedUserProfile();

      dispatch(
        accountAdminSlice.actions.receiveMappedUserProfiles(mappedUserProfiles),
      );
    } catch {
      dispatch(accountAdminSlice.actions.receiveMappedUserProfilesError());
    }
  };

export const createAccountUser =
  (request: Contact): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(accountAdminSlice.actions.creatingUser());
    try {
      const newUser = await createUser(request);
      dispatch(accountAdminSlice.actions.createdUser(newUser));
    } catch {
      dispatch(accountAdminSlice.actions.createUserError());
    }
  };

export const resetCurrentCreateUser =
  (): AppThunk => (dispatch: AppDispatch) => {
    dispatch(accountAdminSlice.actions.createUserStatusReset());
  };

export const resetUpdateUser = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(accountAdminSlice.actions.resetUpdateUser());
};

export const updateAccountUser =
  (request: UpdateUserObject): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(accountAdminSlice.actions.updatingUser());
    try {
      const user = await updateUser(request);
      dispatch(accountAdminSlice.actions.updatedUser(user));
    } catch {
      dispatch(accountAdminSlice.actions.updateUserError());
    }
  };

export const activateAccountUser =
  (request: ProfileApprovals): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(accountAdminSlice.actions.activatingUser());
    try {
      const user = await activateUser(request);
      dispatch(accountAdminSlice.actions.activatedUser(user));
    } catch {
      dispatch(accountAdminSlice.actions.activateUserError());
    }
  };

export const fetchPagedUsers =
  (request: any): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(accountAdminSlice.actions.fetchingNewPagedUsers());
    try {
      const userProfileRequest = new UserProfilesRequest();
      userProfileRequest.searchString = request.searchString;
      userProfileRequest.pageSize = request.pageSize;
      userProfileRequest.pageNumber = request.pageNumber;
      userProfileRequest.accounts = request.accounts;
      const result = await postRestPagedUserProfiles(userProfileRequest);
      dispatch(accountAdminSlice.actions.successFetchingNewPagedUsers(result));
    } catch {
      dispatch(accountAdminSlice.actions.errorFetchingNewPagedUsers());
    }
  };

const combinedUserProfilesWithContact = (data: any) => {
  const { updatedUserProfiles, updatedContacts } = data;
  return _.map(updatedUserProfiles, (profile) => {
    const attachedContact = updatedContacts.find(
      (contact: Contact) => contact.id === profile.contactId,
    );
    return {
      ...profile,
      contact: attachedContact,
    };
  });
};

const updateMappedUserProfile = (
  mappedUserProfiles: UserProfile[],
  updatedUsers: any,
) => {
  const { updatedUserProfiles, updatedContacts } = updatedUsers;
  return _.map(mappedUserProfiles, (userProfile) => {
    if (userProfile.id === updatedUserProfiles[0].id) {
      return {
        ...updatedUserProfiles[0],
        contact: updatedContacts[0],
      };
    }
    return userProfile;
  });
};

export const selectAccounts = (state) => state.accountAdminRtk.accounts;
export const selectAccountNotifications = (state) =>
  state.accountAdminRtk.accountNotifications;

export default accountAdminSlice.reducer;
