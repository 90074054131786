import { useQuery } from "@tanstack/react-query";
import {
  AssessmentOverviewDto,
  AssessmentProgram,
  AssessmentsClient,
} from "@lib/ShiOneClient";
import ms from "ms";
import { CACHE_KEY_ASSESSMENTS_OVERVIEW } from "@features/assessments-feature/constants";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";
import { handleOverviewDemoData } from "@features/assessments-feature/helpers/assessmentDemoDataHelpers";

const api = new AssessmentsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT,
);

const useGetAssessmentOverview = (
  assessmentProgram?: AssessmentProgram,
  assessmentId?: string,
) => {
  const { setDemoMode, isDemoMode } = useAssessmentDemoData();
  const queryFn = async () => {
    if (!assessmentProgram) return null;
    if (isDemoMode.get(assessmentProgram))
      return handleOverviewDemoData(assessmentProgram);
    const result = api.getAssessmentOverview(assessmentProgram, assessmentId);
    return result
      .then((r) => {
        return r;
      })
      .catch((except) => {
        if (except.status === 404) {
          setDemoMode(true, assessmentProgram);
          return handleOverviewDemoData(assessmentProgram);
        }
      });
  };

  return useQuery<AssessmentOverviewDto, Error>({
    queryKey: [CACHE_KEY_ASSESSMENTS_OVERVIEW, assessmentProgram, assessmentId],
    queryFn: async () => queryFn().then(),
    staleTime: ms("1m"),
    enabled: !!assessmentProgram,
  });
};

export default useGetAssessmentOverview;
