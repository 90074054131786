import {
  ExportComponent,
  TabExportType,
} from "@features/assessments-feature/types/TabTypes";
import { assessmentTabIds } from "@features/assessments-feature/constants";
import MaturityChartCardExport from "@features/assessments-feature/components/assessment-export/MaturityChartCardExport";
import MaturityChartGridExport from "@features/assessments-feature/components/assessment-export/MaturityChartGridExport";
import SummaryExport from "@features/assessments-feature/components/assessment-export/SummaryExport";
import TargetMaturityBreakdownExport from "@features/assessments-feature/components/assessment-export/TargetMaturityBreakdownExport";
import PrioritiesRecsTableExport from "@features/assessments-feature/components/assessment-export/PrioritiesRecsTableExport";
import PrioritiesRecsListExport from "@features/assessments-feature/components/assessment-export/PrioritiesRecsListExport";
import PlatformInsightsExport from "@features/assessments-feature/components/assessment-export/PlatformInsightsExport";
import CapabilityInsightsExport from "@features/assessments-feature/components/assessment-export/CapabilityInsightsExport";

export const assessmentTabsAndExports: TabExportType[] = [
  {
    tabId: assessmentTabIds.overview,
    tabName: "Overview",
    componentsToPrint: [
      {
        name: "Summary",
        component: SummaryExport,
      },
      {
        name: "Target Maturity Breakdown",
        component: TargetMaturityBreakdownExport,
      },
    ],
  },
  {
    tabId: assessmentTabIds.maturityChart,
    tabName: "Maturity Chart",
    componentsToPrint: [
      {
        name: "Card view",
        component: MaturityChartCardExport,
      },
      {
        name: "Table view",
        component: MaturityChartGridExport,
      },
    ],
  },
  {
    tabId: assessmentTabIds.recommendations,
    tabName: "Priorities & Recommendations",
    componentsToPrint: [
      {
        name: "List View",
        component: PrioritiesRecsListExport,
      },
      { name: "Table View", component: PrioritiesRecsTableExport },
    ],
  },
  // {
  //   tabId: assessmentTabIds.platformInsights,
  //   tabName: "Platform Insights",
  //   componentsToPrint: [
  //     {
  //       name: "Platform Insights",
  //       component: PlatformInsightsExport,
  //     },
  //   ],
  // },
  // {
  //   tabId: assessmentTabIds.capabilityInsights,
  //   tabName: "Capability Insights",
  //   componentsToPrint: [
  //     {
  //       name: "Capability Insights",
  //       component: CapabilityInsightsExport,
  //     },
  //   ],
  // },
];

export const assessmentTabComponentToPrint = () => {
  let componentList: ExportComponent[] = [];
  assessmentTabsAndExports.forEach((tab) =>
    componentList.push(...tab.componentsToPrint)
  );
  return componentList;
};
