import React, { useState } from "react";
import useGetAssessmentRecommendations from "@features/assessments-feature/hooks/useGetAssessmentRecommendations";
import {
  assessmentTabViewMode,
  sortedPriorities,
} from "@features/assessments-feature/constants";
import { useAssessmentViewContext } from "@features/assessments-feature/contexts-providers/AssessmentViewContextProvider";
import ErrorIcon from "@mui/icons-material/Error";
import { Typography } from "@mui/material";
import PrioritiesRecsList from "@features/assessments-feature/components/priorities-recommendations/PrioritiesRecsList";
import PrioritiesRecsTable from "@features/assessments-feature/components/priorities-recommendations/PrioritiesRecsTable";
import DemoModeAssessmentRecs from "@features/assessments-feature/components/DemoModeAssessmentRecs";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import {
  PrioritizedRecommendation,
  ResponseRecommendation,
} from "@lib/ShiOneClient";
import { useLocation } from "react-router-dom";
import RecommendationsDialog from "@features/assessments-feature/components/priorities-recommendations/RecommendationsDialog";

const PrioritiesRecsView = ({
  viewMode,
}: {
  viewMode: assessmentTabViewMode;
}) => {
  const { state } = useLocation();
  const { assessmentViewProps, assessmentId } = useAssessmentViewContext();
  const {
    data: prioritiesData,
    isLoading,
    isError,
    isPlaceholderData,
  } = useGetAssessmentRecommendations(
    assessmentViewProps?.id,
    assessmentId ?? undefined,
  );
  const { isDemoMode } = useAssessmentDemoData();

  const [openRecsDialog, setOpenRecsDialog] = useState(false);
  const [currentRecs, setCurrentRecs] = useState<ResponseRecommendation>();
  const handleRecsDialogOpenState = (
    recsValue: ResponseRecommendation | undefined,
    isOpen: boolean,
  ) => {
    setOpenRecsDialog(isOpen);
    setCurrentRecs(recsValue);
  };
  const loading = isLoading || isPlaceholderData;

  if (isError) {
    return (
      <>
        <ErrorIcon color={"error"} />
        <Typography component="span" color={"error"} ml={2}>
          Error loading priorities and recommendation
        </Typography>
      </>
    );
  }

  if (assessmentViewProps?.id && isDemoMode.get(assessmentViewProps?.id)) {
    return (
      <DemoModeAssessmentRecs assessmentProgram={assessmentViewProps?.id} />
    );
  }

  const sortedPrioritizedRecs: PrioritizedRecommendation[] =
    prioritiesData?.prioritizedRecommendations?.sort(
      (a: PrioritizedRecommendation, b: PrioritizedRecommendation) =>
        sortedPriorities[a.priority ?? "Unknown"] -
        sortedPriorities[b.priority ?? "Unknown"],
    ) ?? [];

  const currentRecsFromState = sortedPrioritizedRecs
    .flatMap((capability) => capability.responseRecommendations)
    ?.find((rec) => {
      if (state?.capability && rec?.capability)
        return rec.capability === state.capability;
    });

  const isOpenDialog = (state?.openRecsDialog || openRecsDialog) && !loading;

  return (
    <>
      {viewMode === assessmentTabViewMode.list && (
        <PrioritiesRecsList
          sortedPrioritizedRecs={sortedPrioritizedRecs}
          handleRecsDialogOpenState={handleRecsDialogOpenState}
          loading={loading}
        />
      )}

      {viewMode === assessmentTabViewMode.table && (
        <PrioritiesRecsTable
          sortedPrioritizedRecs={sortedPrioritizedRecs}
          handleRecsDialogOpenState={handleRecsDialogOpenState}
          loading={loading}
        />
      )}
      {isOpenDialog && (
        <RecommendationsDialog
          openRecsDialog={isOpenDialog}
          currentRecs={currentRecs || currentRecsFromState}
          handleRecsDialog={handleRecsDialogOpenState}
        />
      )}
    </>
  );
};

export default PrioritiesRecsView;
