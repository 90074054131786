import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { AppendIfPrefixExists } from "../../../../../modules/sitePaths";
import { RecordsDetail } from "@features/projects-feature/projectTypes";
import { numberFormatter } from "@features/assets-feature/utils/assetInventoryFunctions";
import React from "react";
import { ItemBox } from "shared-ui";

export const RecordsDetailItem = ({
  recordDetails,
  loading,
}: {
  recordDetails: RecordsDetail;
  loading: boolean;
}) => {
  const { linkTo, contractName, count, title } = recordDetails;

  function fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return "%" + c.charCodeAt(0).toString(16);
    });
  }

  const encodedLinkTo = linkTo + fixedEncodeURIComponent(contractName);

  const forceReloadInvoicesLink = (
    _event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    if (count > 0 && encodedLinkTo.includes("/services/billing")) {
      window.location.href = AppendIfPrefixExists(encodedLinkTo);
    }
  };

  return (
    <ItemBox
      loading={loading}
      label={title}
      data={numberFormatter.format(count)}
      tileActions={
        <Button
          component={RouterLink}
          disabled={!count}
          to={count > 0 ? AppendIfPrefixExists(encodedLinkTo) : ""}
          onClick={forceReloadInvoicesLink}
        >
          View records
        </Button>
      }
    />
  );
};
