import { Box, Tab, TabProps } from "@mui/material";
import React from "react";
import LinkBehavior from "../theme/overrides/LinkBehavior";

export type ShiTabType = {
  tabId: string;
  tabName: string;
  tabDisabled?: boolean;
};

interface ShiTabProps extends TabProps {
  href?: string;
  state?: any;
}

const ShiTab = (props: ShiTabProps) => {
  const { label, ...otherProps } = props;
  return (
    <Tab
      label={<Box px={3}>{label}</Box>}
      LinkComponent={LinkBehavior}
      {...otherProps}
    />
  );
};

export default ShiTab;
