import React from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { SortingOrder } from "./constants";
import MuiDataGridToolBar from "./components/MuiDataGridToolBar";
import StyledDataGrid from "../../theme/extensions/StyledDataGrid";
import NoTableData from "../../global-layout/NoTableData";
import SkeletonLoadingDataGrid from "../SkeletonLoadingDataGrid";
import { ShiDataGridProps } from "../../interfaces/ShiDataGridInterface";
import useDataGridStickyHeader from "../../utils/useDataGridStickyHeader";
import DownloadIcon from "@mui/icons-material/Download";
import LineWeightIcon from "@mui/icons-material/LineWeight";
import { Box } from "@mui/material";

const MuiDataGrid: React.FC<ShiDataGridProps> = ({
  loading = false,
  gridColsDef,
  gridRows,
  hideSelectAllCheckBox = false,
  onPageChangeHandler,
  onSortChangeHandler,
  adjustRowHeight = true,
  handleRowSelection,
  rowExpandLookup,
  sortingOrder = [SortingOrder.Descending, SortingOrder.Ascending, null],
  saveUserConfig = false,
  gridId = undefined,
  disableRowSelectionOnClick = true,
  handleTableReset,
  customToolbar,
  pagination = true,
  containerStyle,
  customTheme,
  rowDensityMenu,
  exportMenu,
  viewSelect,
  hideNoTableDataSuggestions,
  hideRowHoverBackground,
  customNoDataMessage,
  userPrefHook,
  treeData,
  apiRef,
  autoHeight = true,
  ...props
}) => {
  const localApiRef = useGridApiRef();
  if (!apiRef) apiRef = localApiRef;
  const {
    handleResetTableView,
    isDefaultView,
    initialState,
    columns,
    defaultInitialState,
    userConfigLoading,
    dataGridTreeDataProps,
  } = userPrefHook({
    gridId,
    apiRef,
    gridColsDef,
    rowExpandLookup,
    treeData,
    saveUserConfig,
  });
  useDataGridStickyHeader(autoHeight, gridId);

  function applyStickyHeader() {
    if (containerStyle) {
      return containerStyle;
    } else if (gridRows.length === 0) {
      return {
        height: "75vh",
      };
    } else return;
  }

  const customNoTableData = () => {
    return (
      <NoTableData
        hideSuggestions={hideNoTableDataSuggestions}
        noDataMessage={customNoDataMessage}
      />
    );
  };

  const getSx = () => {
    let sx = {};

    if (hideSelectAllCheckBox) {
      sx = {
        ...sx,
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
          {
            display: "none",
          },
        "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
          color: "secondary.main",
        },
        "& .MuiCheckbox-root.MuiCheckbox-indeterminate svg": {
          color: "secondary.main",
        },
      };
    }
    if (hideRowHoverBackground) {
      sx = {
        ...sx,
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "transparent",
        },
      };
    }
    if (gridRows?.length === 0) {
      sx = {
        ...sx,
        "& .MuiDataGrid-virtualScroller": {
          overflow: "hidden",
        },
        "& .MuiDataGrid-scrollbar--horizontal": {
          visibility: "hidden",
        },
      };
    }

    return sx;
  };

  return (
    <Box sx={{ ...applyStickyHeader(), width: "100%" }}>
      <StyledDataGrid
        {...dataGridTreeDataProps}
        key={gridId}
        sx={getSx()}
        autoHeight={autoHeight && gridRows.length > 0}
        apiRef={apiRef}
        loading={loading || userConfigLoading}
        pagination={pagination}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        columns={columns}
        rows={gridRows}
        paginationMode={props.paginationMode ?? "client"}
        onRowSelectionModelChange={handleRowSelection}
        disableColumnFilter={props.disableColumnFilter ?? true}
        onPaginationModelChange={onPageChangeHandler}
        onSortModelChange={onSortChangeHandler}
        adjustRowHeight={adjustRowHeight}
        getRowHeight={() => {
          return adjustRowHeight ? "auto" : null;
        }}
        initialState={defaultInitialState}
        pageSizeOptions={[15, 25, 50]}
        customTheme={customTheme}
        localeText={{
          toolbarDensity: <LineWeightIcon fontSize={"small"} />,
          toolbarExport: <DownloadIcon fontSize={"small"} />,
        }}
        slots={{
          loadingOverlay: SkeletonLoadingDataGrid,
          toolbar: MuiDataGridToolBar,
          noRowsOverlay: customNoTableData,
          noResultsOverlay: customNoTableData,
        }}
        slotProps={{
          toolbar: {
            customToolbar: customToolbar,
            checkboxSelection: props.checkboxSelection,
            saveUserConfig: saveUserConfig,
            handleResetTableView: handleResetTableView ?? null,
            isDefaultView: isDefaultView,
            initialState: initialState,
            viewSelect: viewSelect,
            treeData: treeData,
            rowDensityMenu: rowDensityMenu,
            exportMenu: exportMenu,
          },
        }}
        sortingOrder={sortingOrder}
        sortingMode={props.sortingMode ?? "client"}
        {...props}
      />
    </Box>
  );
};

export default MuiDataGrid;
