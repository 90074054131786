import { useQuery } from "@tanstack/react-query";
import {
  AssessmentMaturityChartDto,
  AssessmentProgram,
  AssessmentsClient,
} from "@lib/ShiOneClient";
import { CACHE_KEY_ASSESSMENTS_MATURITY_CHART } from "@features/assessments-feature/constants";
import ms from "ms";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";
import { handleMaturityDemoData } from "@features/assessments-feature/helpers/assessmentDemoDataHelpers";

const api = new AssessmentsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT,
);

const useGetAssessmentMaturityChart = (
  assessmentProgram?: AssessmentProgram,
  assessmentId?: string,
) => {
  const { setDemoMode, isDemoMode } = useAssessmentDemoData();

  const queryFn = async () => {
    if (!assessmentProgram) return null;
    if (assessmentProgram && isDemoMode.get(assessmentProgram))
      return handleMaturityDemoData(assessmentProgram);
    const result = api.getAssessmentMaturityChart(
      assessmentProgram,
      assessmentId,
    );
    return result
      .then((r) => {
        return r;
      })
      .catch((except) => {
        if (except.status === 404) {
          setDemoMode(true, assessmentProgram);
          return handleMaturityDemoData(assessmentProgram);
        }
      });
  };

  return useQuery<AssessmentMaturityChartDto, Error>({
    queryKey: [
      CACHE_KEY_ASSESSMENTS_MATURITY_CHART,
      assessmentProgram,
      assessmentId,
    ],
    queryFn: async () => queryFn().then(),
    staleTime: ms("3m"),
    enabled: !!assessmentProgram,
  });
};

export default useGetAssessmentMaturityChart;
