import { useEffect, useState } from "react";

const useDataGridStickyHeader = (
  autoHeight: boolean,
  gridId: string | undefined,
) => {
  let stickyDiv = document.querySelector<HTMLElement>(
    ".MuiDataGrid-scrollbar--horizontal",
  );
  const parentContainer =
    document.querySelector<HTMLElement>(".MuiDataGrid-main");

  const container = document.getElementById("main-container");

  const [parentContainerHeight, setParentContainerHeight] = useState<number>(
    parentContainer?.getBoundingClientRect()?.height!,
  );
  const [parentContainerWidth, setParentContainerWidth] = useState<number>(
    parentContainer?.getBoundingClientRect()?.width!,
  );

  const [headerContainer, setHeaderContainer] = useState<HTMLElement | null>(
    null,
  );
  const [parentContainerRectTop, setParentContainerRectTop] =
    useState<number>(0);

  //Observe child elements' existences in DOM,
  //if a child is added/deleted or classNames are modified then get the distance between MuiDataGrid and the top of the page again
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const parentContainer =
          document.querySelector<HTMLElement>(".MuiDataGrid-main");
        if (mutation.type === "childList") {
          const topContainer = document.querySelector<HTMLElement>(
            ".MuiDataGrid-topContainer",
          );
          setHeaderContainer(topContainer);
          const loadingBar = document.querySelector<HTMLElement>(
            ".MuiDataGrid-overlayWrapperInner .MuiLinearProgress-root",
          );
          const containerLoadingBar = document.querySelector<HTMLElement>(
            ".MuiDataGrid-overlayWrapperInner",
          );
          setParentContainerRectTop(
            (parentContainer?.getBoundingClientRect()?.top ?? 0) +
              (container?.scrollTop ?? 0),
          );
          if (loadingBar && containerLoadingBar) {
            containerLoadingBar.style.position = "relative";
            loadingBar.style.position = "absolute";
            loadingBar.style.top = topContainer?.style.top ?? 0 + "px";
            loadingBar.style.width = "100%";
            loadingBar.style.zIndex = "2";
          }
        } else if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          setParentContainerRectTop(
            (parentContainer?.getBoundingClientRect()?.top ?? 0) +
              (container?.scrollTop ?? 0),
          );
        }
      });
    });
    const config = { attributes: true, childList: true, subtree: true };
    observer.observe(document.body, config);

    return () => observer.disconnect();
  }, []);
  const headerContainerRectHeight = headerContainer?.clientHeight ?? 0;

  // Observe when the data grid container resize to get its height and width and adjust the horizontal scrollbar position
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const { height, width } = entries[0].contentRect;
      setParentContainerHeight(height);
      setParentContainerWidth(width);
      setParentContainerRectTop(
        parentContainer?.getBoundingClientRect()?.top! + container?.scrollTop!,
      );
      handleGridHorizontalScrollbar(height, width, container?.scrollTop);
    });
    if (parentContainer) observer.observe(parentContainer);
    return () => {
      observer.disconnect();
    };
  }, [parentContainer]);

  // Scroll EventListener, adjust position of the header container and the horizontal scrollbar container position.
  useEffect(() => {
    const handleScroll = () => {
      if (autoHeight) {
        const scrollTop = container?.scrollTop ?? 0;

        const scrollDifference = Math.max(
          scrollTop + headerContainerRectHeight - 6 - parentContainerRectTop,
          0,
        );

        if (headerContainer) {
          if (
            parentContainerRectTop - headerContainerRectHeight < scrollTop &&
            scrollTop !== 0
          ) {
            headerContainer.style.top = scrollDifference + "px";
          } else {
            headerContainer.style.top = "0px";
          }
        }
        handleGridHorizontalScrollbar(
          parentContainerHeight,
          parentContainerWidth,
          scrollTop,
        );
      }
    };

    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [
    headerContainerRectHeight,
    parentContainerWidth,
    parentContainerHeight,
    gridId,
    parentContainerRectTop,
  ]);

  const handleGridHorizontalScrollbar = (
    currentHeight: number,
    currentWidth: number,
    scrollTop = 0,
  ) => {
    if (stickyDiv) {
      const scrollContainerHeight = window.innerHeight;
      const bottomContToTopView = currentHeight + parentContainerRectTop;
      if (
        scrollContainerHeight < bottomContToTopView &&
        scrollTop + scrollContainerHeight < bottomContToTopView
      ) {
        stickyDiv.style.position = "fixed";
        stickyDiv.style.bottom = "0px";
        stickyDiv.style.width = currentWidth + "px";
      } else {
        stickyDiv.style.position = "absolute";
        stickyDiv.style.width = "100%";
      }
    }
  };
};
export default useDataGridStickyHeader;
