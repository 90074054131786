import { useQuery } from "@tanstack/react-query";
import {
  AssessmentDto,
  AssessmentProgram,
  AssessmentsClient,
} from "@lib/ShiOneClient";
import ms from "ms";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";
import { CACHE_KEY_ASSESSMENTS } from "@features/assessments-feature/constants";

const api = new AssessmentsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT,
);

const useGetAssessments = (program?: AssessmentProgram) => {
  const queryFn = async () => {
    const result = api.getAssessments(program);
    return result
      .then((r) => {
        return r;
      })
      .catch((except) => {
        console.log(except);
      });
  };

  return useQuery<AssessmentDto[], Error>({
    queryKey: [CACHE_KEY_ASSESSMENTS, program ?? "Unknown"],
    queryFn: async () => queryFn().then(),
    staleTime: ms("1m"),
  });
};

export default useGetAssessments;
