import React from "react";
import { AssessmentProgram } from "@lib/ShiOneClient";
import { assessmentPrograms } from "@features/assessments-feature/constants";
import { AutoAwesome, Security } from "@mui/icons-material";
import AssessmentView from "@features/assessments-feature/components/AssessmentView";

export interface AssessmentViewProps {
  id: AssessmentProgram;
  name: string;
  description: string;
  icon: React.ReactElement;
  component: React.ReactNode;
  url: string;
  promoPic?: string;
  promoUrl?: string;
}

const assessmentViewsMap: Map<string, AssessmentViewProps> = new Map<
  string,
  AssessmentViewProps
>([
  [
    assessmentPrograms.spr,
    {
      id: AssessmentProgram.SecurityPostureReview,
      name: "Security Posture Review",
      description:
        "Aims to map your security architecture, identify gaps, highlight overlaps, and reveal opportunities for consolidation in your security posture.",
      icon: <Security />,
      url: "/assessments/" + assessmentPrograms.spr,
      component: <AssessmentView />,
      promoPic:
        "https://shionestorage.blob.core.windows.net/shione/SecurityPostureReview.webp",
      promoUrl:
        "https://shionestorage.blob.core.windows.net/shione/SecurityPostureReview-OnePager.pdf",
    },
  ],
  [
    assessmentPrograms.aiReadiness,
    {
      id: AssessmentProgram.AiReadiness,
      name: "AI Readiness",
      description:
        "Evaluates your current systems and processes to identify potential gaps and deliver actionable solutions for effective AI adoption.",
      icon: <AutoAwesome />,
      url: "/assessments/" + assessmentPrograms.aiReadiness,
      component: <AssessmentView />,
      promoPic:
        "https://shionestorage.blob.core.windows.net/shione/AIReadiness.webp",
      promoUrl:
        "https://shionestorage.blob.core.windows.net/shione/AIReadiness-OnePager.pdf",
    },
  ],
  [
    assessmentPrograms.dopr,
    {
      id: AssessmentProgram.DevOpsPostureReview,
      name: "DevOps Posture Review",
      description:
        "Identifies potential gaps in your tooling & processes, and analyzes your maturity against indicators of high performing teams to recommend key areas of focus and help prioritize DevOps initiatives.",
      icon: <AutoAwesome />,
      url: "/assessments/" + assessmentPrograms.dopr,
      component: <AssessmentView />,
      promoPic:
        "https://shionestorage.blob.core.windows.net/shione/AIReadiness.webp",
      promoUrl:
        "https://shionestorage.blob.core.windows.net/shione/AIReadiness-OnePager.pdf",
    },
  ],
]);
export default assessmentViewsMap;
