const shiClaimBase = "https://one.shi.com/claim-type/";
export const claimTypes = {
  accountId: `${shiClaimBase}account-id`,
  axNumber: `${shiClaimBase}ax-number`,
  primaryAxNumber: `${shiClaimBase}ax-number-primary`,
  contactId: `${shiClaimBase}contact-id`,
  firstName: `${shiClaimBase}first-name`,
  hasShiClaims: `${shiClaimBase}has-shi-one-claims`,
  impersonatorContactId: `${shiClaimBase}impersonator-contact-id`,
  impersonatorEmail: `${shiClaimBase}impersonator-email`,
  isCustomer: `${shiClaimBase}is-customer`,
  isImpersonating: `${shiClaimBase}is-impersonating`,
  isShi: `${shiClaimBase}is-shi`,
  isTeamsEnabled: `${shiClaimBase}is-teams-enabled`,
  isVirtualAdmin: `${shiClaimBase}is-virtual-admin`,
  lastName: `${shiClaimBase}last-name`,
  legacyPermission: `${shiClaimBase}legacy-permission`,
  permission: `${shiClaimBase}permission`,
  roles: `${shiClaimBase}role-types`,
  userEmail: `${shiClaimBase}user-profile-contact-email`,
  userProfileId: `${shiClaimBase}user-profile-id`,
  isGuestUser: `${shiClaimBase}is-guest-user`,
};
export type ClaimTypes = keyof typeof claimTypes;

export const roleTypes = {
  None: 0,
  CustomerUser: 1,
  CustomerAdmin: 2,
  ShiUser: 4,
  ShiAdmin: 8,
  ShiResource: 16,
  ShiImpersonateUser: 32,
  ShiImpersonateVirtualAdmin: 64,
  ShiOnly: 4 | 8 | 16,
};
export type RoleTypes = keyof typeof roleTypes;

export const permissionResourceTypes = {
  "service/contract": "service/contract",
  "service/invoice": "service/invoice",
  "supportcenter/project": "supportcenter/project",
  "cloud/aws/account": "cloud/aws/account",
  "cloud/aws/report": "cloud/aws/report",
  "cloud/aws/monitoring": "cloud/aws/monitoring",
  "cloud/aws/blueprints": "cloud/aws/blueprints",
  "cloud/aws/recommendations": "cloud/aws/recommendations",
  "cloud/aws/compliance": "cloud/aws/compliance",
  "cloud/azure/subscription": "cloud/azure/subscription",
  "cloud/azure/report": "cloud/azure/report",
  "cloud/azure/blueprints": "cloud/azure/blueprints",
  "cloud/azure/monitoring": "cloud/azure/monitoring",
  "cloud/azure/backup": "cloud/azure/backup",
  "cloud/azure/recommendations": "cloud/azure/recommendations",
  "cloud/azure/compliance": "cloud/azure/compliance",
  "cloud/o365/subscription": "cloud/o365/subscription",
  "cloud/gcp/account": "cloud/gcp/account",
  "cloud/gcp/report": "cloud/gcp/report",
  "cloud/gcp/project": "cloud/gcp/project",
  "cloud/datadog/report": "cloud/datadog/report",
  "assets/warehouse": "assets/warehouse",
  "assets/integrationCenter/reports": "assets/integrationCenter/reports",
  "assets/managed": "assets/managed",
  "assets/estate": "assets/estate",
  "cisco/reports": "cisco/reports",
  "dashboards/multicloudConsumption": "dashboards/multicloudConsumption",
};
export type PermissionResourceTypes = keyof typeof permissionResourceTypes;

export const permissionActionTypes = {
  "contract:ReadContracts": "contract:ReadContracts",
  "invoice:ReadInvoices": "invoice:ReadInvoices",
  "project:ReadProjects": "project:ReadProjects",
  "account:ReadAccounts": "account:ReadAccounts",
  "account:CreateAccounts": "account:CreateAccounts",
  "report:ReadReports": "report:ReadReports",
  "alert:ReadAlerts": "alert:ReadAlerts",
  "alert:DeployBlueprints": "alert:DeployBlueprints",
  "recommendations:ReadRecommendations": "recommendations:ReadRecommendations",
  "compliance:ReadCompliance": "compliance:ReadCompliance",
  "backup:ReadAlerts": "backup:ReadAlerts",
  "backup:ReadBackup": "backup:ReadBackup",
  "assets:ReadAssetEstate": "assets:ReadAssetEstate",
  "assets:ReadManagedDevices": "assets:ReadManagedDevices",
  "assets:ReadWarehouseInventory": "assets:ReadWarehouseInventory",
  "assets:ReadWarehouseOrders": "assets:ReadWarehouseOrders",
};
export type PermissionActionTypes = keyof typeof permissionActionTypes;

export const enum ShiOnePermission {
  aiReadinessRead = "ai-readiness-read",
  assetEstateRead = "asset-estate-read",
  awsAccountCreate = "aws-account-create",
  awsAccountRead = "aws-account-read",
  awsBlueprintDeploy = "aws-blueprint-deploy",
  awsComplianceRead = "aws-compliance-read",
  awsConsumptionRead = "aws-consumption-read",
  awsCostAllocationTagRead = "aws-cost-allocation-tag-read",
  awsInvoiceRead = "aws-invoice-read",
  awsMonitoringAlertRead = "aws-monitoring-alert-read",
  awsRecommendationRead = "aws-recommendation-read",
  azureAccountCreate = "azure-account-create",
  azureAccountRead = "azure-account-read",
  azureBackupAlertRead = "azure-backup-alert-read",
  azureBlueprintDeploy = "azure-blueprint-deploy",
  azureComplianceRead = "azure-compliance-read",
  azureConsumptionRead = "azure-consumption-read",
  azureInvoiceRead = "azure-invoice-read",
  azureMonitoringAlertRead = "azure-monitoring-alert-read",
  azureRecommendationRead = "azure-recommendation-read",
  ciscoReportRead = "cisco-report-read",
  datadogConsumptionRead = "datadog-consumption-read",
  gcpAccountRead = "gcp-account-read",
  gcpConsumptionRead = "gcp-consumption-read",
  gcpInvoiceRead = "gcp-invoice-read",
  knowledgeBaseRead = "knowledge-base-read",
  labsRead = "labs-read",
  labsOnDemandRequest = "labs-on-demand-request",
  managedDevicesRead = "asset-managed-devices-read",
  multicloudConsumptionRead = "multicloud-consumption-read",
  o365AccountRead = "o365-account-read",
  o365BackupStatusRead = "o365-backup-status-read",
  o365InvoiceRead = "o365-invoice-read",
  reportEdit = "report-edit",
  reportRead = "report-read",
  securityPostureReviewRead = "security-posture-review-read",
  devOpsPostureReviewRead = "dev-ops-posture-review-read",
  serviceContractRead = "service-contract-read",
  serviceInvoiceRead = "service-invoice-read",
  serviceProjectRead = "service-project-read",
  settingsCostCenterManage = "settings-cost-center-manage",
  settingsGeneralManage = "settings-general-manage",
  settingsIntegrationManage = "settings-integration-manage",
  settingsUserManage = "settings-user-manage",
  supportRequestRead = "support-request-read",
  supportRequestReadAll = "support-request-read-all",
  supportRequestSubmit = "support-request-submit",
  warehouseInventoryRead = "warehouse-inventory-read",
  warehouseOrderRead = "warehouse-order-read",
  catalystRead = "catalyst-read",
}
